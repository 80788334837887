import React, { useState, useEffect } from "react";
import axios from "../api/axios";
import regions from "./Regions";
import logo from "../../assets/images/logoBlue.png";
const REGISTERTATION = "/api/registration";
function SignUp() {
  const [formStep, setFormStep] = useState(0);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    region: "",
    cityzone: "",
    subcityworeda: "",
    pobox: "",
    snl: "",
    ssn: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [selectedRegion, setSelectedRegion] = useState("");
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const validateStep0 = () => {
    const errors = {};
    if (!formData.firstname.trim()) {
      errors.firstname = "*";
    }
    if (!formData.lastname.trim()) {
      errors.lastname = "*";
    }
    if (!formData.username.trim()) {
      errors.username = "*";
    }
    if (!formData.email.trim()) {
      errors.email = "*";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid Email";
    }
    if (!formData.password.trim()) {
      errors.password = "*";
    } else if (formData.password.length < 8) {
      errors.password = "Must be at least 8 characters long";
    }
    if (!formData.confirmpassword.trim()) {
      errors.confirmpassword = "*";
    } else if (formData.password !== formData.confirmpassword) {
      errors.confirmpassword = "Passwords do not match";
    }
    console.log("Step 0 errors:", errors);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    const isStep0Valid = validateStep0();

    if (!isStep0Valid) {
      console.error("Error: Form is invalid");
      return;
    }
    try {
      const response = await axios.post(
        REGISTERTATION,
        JSON.stringify({
          firstname: formData.firstname,
          lastname: formData.lastname,
          email: formData.email,
          password: formData.password,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data.status === 200) {
        setFormData("");
        window.location.href = "/thanks";
      } else {
        // setError("Domain Registration Failed");
      }
    } catch (err) {
      // setError("No Server Response");
    }
  };

  return (
    <section className="container-form container-form-sign-up">
      <form onSubmit={handleRegister} className="sign-up-form">
        {formStep === 0 && (
          <div className="personal-info-form peronal-address-form">
            <div className="register-first-page">
              <img src={logo} />
              <h1>Personal Information</h1>
            </div>
            <input
              type="text"
              placeholder="First Name"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              required
              autoComplete="on"
            />
            {formErrors.firstname && (
              <div className="error">{formErrors.firstname}</div>
            )}
            <input
              type="text"
              placeholder="Last Name"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              required
              autoComplete="on"
            />
            {formErrors.lastname && (
              <div className="error">{formErrors.lastname}</div>
            )}

            <input
              type="text"
              placeholder="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              autoComplete="on"
            />
            {formErrors.username && (
              <div className="error">{formErrors.username}</div>
            )}
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              autoComplete="on"
            />
            {formErrors.email && (
              <div className="error">{formErrors.email}</div>
            )}
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {formErrors.password && (
              <div className="error">{formErrors.password}</div>
            )}
            <input
              type="password"
              placeholder="Confirm Password"
              name="confirmpassword"
              value={formData.confirmpassword}
              onChange={handleChange}
              required
            />
            {formErrors.confirmpassword && (
              <div className="error">{formErrors.confirmpassword}</div>
            )}
            <button
              type="submit"
              className="signIn-register-btn next-back-reg-btn next-back-btn"
            >
              Register
            </button>
          </div>
        )}
      </form>
    </section>
  );
}

export default SignUp;
