import React from "react";
import "./ThankYouPage.css";
import logo from "../../assets/images/logoWhite.png";
const ThankYouPage = () => {
  return (
    <div className="body">
      <div className="thank-you-container">
        <h1 className="thank-you-heading">Thank You for Registering!</h1>
        <p className="thank-you-text">
          We are currently developing this website to provide you with an
          amazing user experience.
        </p>
        <p className="thank-you-text">
          Stay tuned for exciting updates and new features!
        </p>
        <p className="thank-you-text">
          If you have any questions or feedback, please feel free to contact us.
        </p>
        <p className="thank-you-text">Happy exploring!</p>
      <a href='/'> <img src={logo} className="thank-you-image" alt="Logo" /></a>
      </div>
    </div>
  );
};

export default ThankYouPage;
