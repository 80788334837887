import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logoWhite.png";
import axios from "../api/axios";
const LOGIN_URL = "/api/login";
function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  // const { login } = useAuth(); // Use the login function from the useAuth hook

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  useEffect(() => {
    setEmail("");
    setPassword("");
  }, []);
  const SetNull = () => {
    setError("");
  };
  const isPasswordValid = (password) => {
    return password.length >= 8;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isEmailValid(email)) {
      alert("Invalid email");
      return;
    }
    if (!isPasswordValid(password)) {
      alert("Password must be at least 8 characters");
      return;
    }
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data.status === 200) {
        window.location.href = "/underdev";
        // const datas = new URLSearchParams(data).toString();
        // window.location.href = `http://dashboard.localhost:3000/?${datas}`;
        // window.location.href = `https://dashboard.earaldtradinget.com/?${datas}`;
      } else if (response.data.status === 400) {
        setError("Invalid email or password");
      } else if (response.data.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Login Failed");
      }
      setEmail("");
      setPassword("");
    } catch (err) {
      if (err) {
        setError("No Server Response");
      }
    }
  };
  return (
    <section className="container-form container-form-sign-in">
      <div className="container-logo">
        <a href="/">
          {" "}
          <img src={logo} className="login-logo" alt="Logo" />
        </a>
        <h1>Sign In</h1>
      </div>
      {error && (
        <span className="errmsg" aria-live="assertive">
          {error}
        </span>
      )}
      <br />
      <form onSubmit={handleSubmit} className="sign-in-form">
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={email}
          onFocus={SetNull}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="on"
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          onFocus={SetNull}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <a href="#" className="forget_password">
          Forgot Password
        </a>
        <button type="submit" className="signIn-register-btn">
          Sign In
        </button>
      </form>
    </section>
  );
}

export default SignIn;
