import React from "react";
import sampleImage from "../../assets/images/under.jpg";

export const UnderDevelopment = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
  };

  const imageStyle = {
    width: "300px",
    height: "auto",
    marginTop: "20px",
  };
  return (
    <div style={containerStyle}>
      <img style={imageStyle} src={sampleImage} alt="under Development" />
      <h1>Under Development</h1>
      <p>This page is currently under development. Please check back later.</p>
    </div>
  );
};

export default UnderDevelopment;
